import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { //重定向
    path: '/',
    redirect: '/home',
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home.vue"),
    children: [
      {
        path: "/home",
        name: "index",
        component: () => import("@/views/index.vue"),
      },
      {
        path: "verify",
        name: "enterVerify",
        component: () => import("@/components/login/enterVerify.vue"),
      },
      {
        path: "mobile",
        name: "mobile",
        component: () => import("@/components/login/mobile.vue"),
      },
      {
        path: "password",
        name: "resetPassword",
        component: () => import("@/components/login/password.vue"),
      },
      {
        path: "create",
        name: "createPlate",
        component: () => import("@/components/login/createPlate.vue"),
      },
      // {
      //   path: "notice",
      //   name: "notice",
      //   component: () => import("@/components/account/notify_tab.vue"),
      // },
    ],
  },
  {
    path: "",
    name: "loginTab",
    component: () => import("@/views/login.vue"),
    children: [
      { //從帳號登入/app/login?source=accountMain 從洗車登入/app/login?source=carwash
        path: "/login",
        name: "login",
        component: () => import("@/components/login/login.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: () => import("@/components/login/register.vue"),
      },
    ],
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import("@/views/notifyTab.vue"),
  },
  { 
    path: "/notice/expire",
    name: "noticeExpire",
    component: () => import("@/views/notifyExpire.vue"),
  },
  { //推播用&個人通知，到會員優惠券(個人通知會出現雙層關閉符號)
    path: "/notice/coupons",
    name: "noticeCoupon",
    component: () => import("@/views/notifyCoupon.vue"),
  },
  {
    path: "/account",
    name: "accountMain",
    component: () => import("@/views/account.vue"),
  },
  {
    path: "",
    name: "accountBasic",
    component: () => import("@/views/accountBasic.vue"),
    children: [
      {
        path: "",
        name: "accountTab",
        component: () => import("@/components/account/info_tab.vue"),
        children: [
          {
            path: "/account/info",
            name: "accountInfo",
            component: () => import("@/components/account/info_basic.vue"),
          }, 
          {
            path: "/account/info/edit",
            name: "appEditInfo",
            component: () => import("@/components/account/edit_info_app.vue"),
            children: [
              {
                path: "name",
                name: "editName",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
              {
                path: "email",
                name: "editEmail",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
              {
                path: "birthday",
                name: "editBirthday",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
              {
                path: "gender",
                name: "editGender",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
              {
                path: "myVehicle",
                name: "editVehicle",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
            ],
          },
          {
            path: "/account/password",
            name: "password",
            component: () => import("@/components/account/password.vue"),
          },
        ],
      },
      {
        path: "account/info/edit/add",
        name: "addPlateInfo",
        component: () => import("@/components/account/edit_addPlate.vue"),
      },
    ],
  },
  {
    path: "/account/card",
    name: "memberCard",
    component: () => import("@/views/accountMemberCard.vue"),
  },
  {
    path: "/account/payment",
    name: "payment",
    component: () => import("@/views/accountPayment.vue"),
  },
  {
    path: "/account/qrcode",
    name: "paymentQrcode",
    component: () => import("@/views/accountQrcode.vue"),
  },
  {
    path: "/account/tickets",
    name: "paymentTicket",
    component: () => import("@/views/accountTicket.vue"),
  },
  {
    path: "/account/tickets/list",
    name: "paymentTicketItem",
    component: () => import("@/views/accountTicketItem.vue"),
  },
  { //推播網址為/account/rewards?source=notice
    path: "/account/rewards",
    name: "rewards",
    component: () => import("@/views/accountReward.vue"),
  },
  {
    path: "/account/rewards/list",
    name: "rewardList",
    component: () => import("@/views/accountRewardList.vue"),
  },
  {
    path: "/account/coupons",
    name: "coupons",
    component: () => import("@/views/accountCoupon.vue"),
  },
  { //合作廠商優惠券領取推播畫面
    path: "/account/partnercoupon",
    name: "partnerCoupon",
    component: () => import("@/views/partnerCoupon.vue"),
  },
  {
    path: "/account/news",
    name: "news",
    component: () => import("@/views/accountNews.vue"),
  },
  { //推播用，推播網址為/account/news/detail/:id
    path: "/account/news/detail/:id",
    name: "newsDetail",
    component: () => import("@/views/accountNewsDetail.vue"),
  },
  {
    path: "/account/gifts",
    name: "gifts",
    component: () => import("@/views/accountGift.vue"),
  },
  {
    path: "/account/giftResult",
    name: "giftResult",
    component: () => import("@/views/accountGiftResult.vue"),
  },
  // {
  //   path: "/account/gifthistory",
  //   name: "gifthistory",
  //   component: () => import("@/views/accountGiftHistory.vue"),
  // },
  { //推播的預設自動洗付款成功到 /account/service?source=notice 與 套票付款成功 /account/service?source=ticket
    path: "/account/service",
    name: "accountService",
    component: () => import("@/views/accountServiceTab.vue"),
  },
  {
    path: "/account/service/carwash/:id",
    name: "accountServiceCarwash",
    component: () => import("@/views/accountServiceCarwash.vue"),
  },
  {
    path: "/account/service/order/:type/:id",
    name: "accountServiceOrder",
    component: () => import("@/views/accountServiceOrder.vue"),
  },
  {
    path: "/account/repair/:id",
    name: "accountRepair",
    component: () => import("@/views/accountServiceRepair.vue"),
  },
  { //推播 受贈收到優惠券 /account/transfer/record?tab=0 套票 /account/transfer/record?tab=1 優惠券 /account/transfer/record?tab=2
    path: "/account/transfer/record",
    name: "accountTransferRecord",
    component: () => import("@/views/accountTransferRecord.vue"),
  },
  {
    path: "/carwash",
    name: "carwash",
    component: () => import("@/views/carwashTabs.vue"),
  },
  // {
  //   path: "/carwash/auto/main",
  //   name: "carwashBasicMain",
  //   component: () => import("@/views/carwashBasicMain.vue")
  // },
  {
    path: "/carwash/auto/step-one",
    name: "carwashBasicOne",
    component: () => import("@/views/carwashBasicOne.vue")
  },
  {
    path: "/carwash/auto/step-two",
    name: "carwashBasicTwo",
    component: () => import("@/views/carwashBasicTwo.vue")
  },
  {
    path: "/carwash/auto/step-three-plan",
    name: "carwashBasicThreePlan",
    component: () => import("@/views/carwashBasicThree_Plan.vue")
  },
  {
    path: "/carwash/auto/step-three-card",
    name: "carwashBasicThreeCard",
    component: () => import("@/views/carwashBasicThree_Card.vue")
  },
  {
    path: "/carwash/auto/result",
    name: "carwashBasicResult",
    component: () => import("@/views/carwashBasicResult.vue")
  },
  { //推播用，推播成功網址為/carwash/auto/pushResult?success=true
    path: "/carwash/auto/pushResult",
    name: "carwashBasicPushResult",
    component: () => import("@/views/carwashBasicPushSuccess.vue")
  },
  { //推播用，推播成功網址為/carwash/setup/pushResult?success=true
    path: "/carwash/setup/pushResult",
    name: "carwashBasicPushResult",
    component: () => import("@/views/carwashBasicPushSuccess.vue")
  },
  { //推播網址為/carwash/single/step-one??washCarMachineDataUuid=&carDataUuid=
    path: "/carwash/single/step-one",
    name: "carwashSingleOne",
    component: () => import("@/views/carwashSingleOne.vue")
  },
  {
    path: "/carwash/single/step-two",
    name: "carwashSingleTwo",
    component: () => import("@/views/carwashSingleTwo.vue")
  },
  {
    path: "/carwash/single/step-three",
    name: "carwashSingleThree",
    component: () => import("@/views/carwashSingleThree.vue")
  },
  { //一般頁面 + 推播用，訂閱到期(個人通知會出現雙層關閉符號)
    path: "/carwash/monthly/step-one",
    name: "carwashMonthlyOne",
    component: () => import("@/views/carwashMonthlyOne.vue")
  },
  {
    path: "/carwash/monthly/step-two",
    name: "carwashMonthlyTwo",
    component: () => import("@/views/carwashMonthlyTwo.vue")
  },
  {
    path: "/carwash/monthly/step-three",
    name: "carwashMonthlyThree",
    component: () => import("@/views/carwashMonthlyThree.vue")
  },
  { //推播用，推播成功網址為/carwash/monthly/pushResult?success=true
    path: "/carwash/monthly/pushResult",
    name: "carwashMonthlyPush",
    component: () => import("@/views/carwashMonthlyPush.vue")
  },
  { //推播用，刪除訂閱信用卡
    path: "/carwash/monthly/pushDelete",
    name: "carwashMonthlyPushDelete",
    component: () => import("@/views/carwashMonthlyPushDelete.vue")
  },
  {
    path: "/carwash/tickets/step-one",
    name: "carwashTicketsOne",
    component: () => import("@/views/carwashTicketsOne.vue")
  },
  {
    path: "/carwash/tickets/step-two",
    name: "carwashTicketsTwo",
    component: () => import("@/views/carwashTicketsTwo.vue")
  },
  {
    path: "/carwash/tickets/step-three",
    name: "carwashTicketsThree",
    component: () => import("@/views/carwashTicketsThree.vue")
  },
  {
    path: "/carwash/checkout",
    name: "carwashCheckout",
    component: () => import("@/views/carwashCheckout.vue")
  },
  {
    path: "/carwash/checkout/result",
    name: "carwashCheckoutResult",
    component: () => import("@/views/carwashCheckoutResult.vue")
  },
  { //付款畫面跳轉到外部後 顯示的空白畫面 引導使用者關閉
    path: "/carwash/checkout/result_blank",
    name: "carwashCheckoutBlank",
    component: () => import("@/views/carwashCheckoutResult_blank.vue")
  },
  { 
    path: "/fortuneWheel",
    name: "fortuneWheel",
    component: () => import("@/views/fortuneWheel.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});
// 在 router 中設定 global 屬性
router.global = { isApp: null };

export default router;
