import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#0055B8',
            secondary: '#D6E7FB',
            accent: '#03703c',
            alert: '#e11900',
            disabled: '#eee',
            normal: "#eee",
            white: "#FFF"
          },
        },
      },
      icons: {
        iconfont: 'fa',
      },
});
