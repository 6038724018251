import { eventBus } from '@/main.js';
export var FCMToken = null;
export var applePayToken = null;

// android 需設定 javaInterface name = "carguru"
// Google SignIn clientId 要依照 google service json file 中 client_type: 3 的 client_id（要注意 package name)
//      還有在 firebase 中的android app 設定中要把 簽名的 SHA1 設上去

export const loginWithGoogle = (callback) => {
  if (window.carguru || window.webkit) {
    if (window.carguru) {
      window.carguru.loginWithGoogle(); // for Android
    }
    if (window.webkit) {
      window.webkit.messageHandlers.carguru.postMessage("loginWithGoogle");
    }

    window.thirdPartyLogin = callback;

    return true;
  }

  return false;
};

export const loginWithFacebook = (callback) => {
  if (window.carguru || window.webkit) {
    if (window.carguru) {
      window.carguru.loginWithFB(); // for Android
    }
    if (window.webkit) {
      window.webkit.messageHandlers.carguru.postMessage("loginWithFB");
    }

    window.thirdPartyLogin = callback;

    return true;
  }

  return false;
};

export const appleSignin = (callback) => {
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage("appleSignin");

    window.thirdPartyLogin = callback;

    return true;
  }
  return false;
};

//這個function是用在 app call 網頁 javascript function 宣告用的
export const thirdPartyLogin = (callback) => {
  // callback function ex: thirdPartyLogin(token, type)
  window.thirdPartyLogin = callback;
};

export const getNotificationToken = () => {
  // callback function ex: setNotificationToken(token)
  window.setNotificationToken = setNotificationToken;
 
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage("getNotificationToken");
  }
  if (window.carguru) {
    const token = window.carguru.getNotificationToken(); // for Android
    //console.log(token);
    //return token;
  }

  return null;
};

export const setNotificationToken = (token) => {
  console.log("setNotificationToken", token);
  FCMToken = token;
};

export const getAppVersion = (callback) => {
  if (window.carguru) {
    window.carguru.getVersion(); // for Android
  }
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage("getVersion");
  }
  // callback function ex: setVersion(version)
  window.setVersion = callback;
};

export const dialPhone = (phone) => {
  var isApp = false;
  if (window.carguru) {
    window.carguru.dialPhone(phone); // for Android
    isApp = true;
  }
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage(
      '{"dialPhone": "' + phone + '"}'
    );
    isApp = true;
  }
  if (!isApp) {
    const tel = "tel:" + phone;
    const a = document.createElement("a");
    a.href = tel;
    a.click();
  }
};

// 開外部新瀏覽器
export const openWeb = (url) => {
  var isApp = false;
  console.log(url)
  if (window.carguru) {
    window.carguru.externalWeb(url); // for Android
    isApp = true;
  }
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage(
      '{"externalWeb": "' + encodeURI(url) + '"}'
    );
    isApp = true;
  }
  if (!isApp) {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.click();
  }
};

// 開啟APP內webview 原生會帶header
export const openNewWebview = (url) => {
  if (window.carguru) {
    window.carguru.openNewWebview(url); // for Android
    console.log("android openWebview")
  }
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage('{"openNewWebview": "' + encodeURI(url) + '"}');
  }
  return null;
};

// 關閉APP內webview
export const closeWebview = () => {
  if (window.carguru) {
    window.carguru.closeWebview(); // for Android
    console.log("android closeWebview")
  }
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage("closeWebview");
  }

  return null;
};

//apple pay
export const applePay = (purchaseUuid, token) => {
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage('{"applePay": "' + purchaseUuid + '",  "label" : "單次洗", "token": "'+ token +'" }');
  }
  return;
};

//取得apple pay的token
export const getApplePayToken = () => {
  window.setApplePayToken = setApplePayToken;
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage("getApplePayToken");
  }
  return null;
};
export const setApplePayToken = (token) => {
  console.log("setApplePayToken", token);
  applePayToken = token
}

export const showToast4Android = (message) => {
  if (window.carguru) {
    window.carguru.showToast(message);
  }
};

export const showToolTab = () => {
  if (window.carguru) {
    window.carguru.showTab(); // for Android
  }
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage("showTab");
  }

  return;
};

export const hideToolTab = () => {
  if (window.carguru) {
    window.carguru.hiddenTab(); // for Android
  }
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage("hiddenTab");
  }

  return;
};

export const backToTab = () => {
  if (window.carguru) {
    window.carguru.backToTab(); // for Android
  }
  if (window.webkit) {
    window.webkit.messageHandlers.carguru.postMessage("backToTab");
  }
  return;
};
