import Vue from 'vue'
import Vuex from 'vuex'
import { getToken } from "@/utils/util";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ["isApp", "isLogin", "token", "user", "thirdParty", "my", "carwash", "carwashType", "urlFrom", "activeCarwashTab", "activeCarwashSubTab", "payStatus", "deviceToken", "ticketGroup", "gift", "activeNoticeTab", "activeRecordTab"],
    }),
  ],
  state: {
    isApp: true,
    isLogin: false,
    token: getToken(),
    deviceToken: "",
    user: {},
    thirdParty: {},
    my: {},
    carwash: {},
    carwashType: "",
    urlFrom: "", // 名稱
    activeCarwashTab: 0,
    activeCarwashSubTab: 0,
    payStatus: "",
    ticketGroup: [],
    gift: {},
    activeNoticeTab: 0,
    activeRecordTab: 0,
    errMsg: {}
  },
  getters: {
    getUser: function (state) {
      return state.user;
    },
    getCarwash: function (state) {
      return state.carwash;
    },
    getCarwashType: function (state) {
      return state.carwashType;
    },
    getUrlFrom: function (state) {
      return state.urlFrom;
    },
    getActiveCarwashTab: function (state) {
      return state.activeCarwashTab;
    },
    getActiveCarwashSubTab: function (state) {
      return state.activeCarwashSubTab;
    },
    getTicketGroup: function (state) {
      return state.ticketGroup;
    },
    getGift: function (state) {
      return state.gift;
    },
    getActiveNoticeTab: function (state) {
      return state.activeNoticeTab;
    },
    getActiveRecordTab: function (state) {
      return state.activeRecordTab;
    },
  },
  mutations: { 
    setIsApp(state, isApp) {
      state.isApp = isApp;
    },
    setIsLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    setUser(state, user) {
      state.user = user;
    },
    setThirdParty(state, thirdParty) {
      state.thirdParty = thirdParty;
    },
    // 設定Token
    setToken(state, token) {
      state.token = token;
    },
    setDeviceToken(state, deviceToken) {
      state.deviceToken = deviceToken;
    },
    setMy(state, my) {
      state.my = my;
    },
    setCarwash(state, carwash) {
      state.carwash = carwash;
    },
    setCarwashType(state, carwashType) {
      state.carwashType = carwashType;
    },
    setUrlFrom(state, urlFrom) {
      state.urlFrom = urlFrom;
    },
    setActiveCarwashTab(state, activeCarwashTab) {
      state.activeCarwashTab = activeCarwashTab;
    },
    setActiveCarwashSubTab(state, activeCarwashSubTab) {
      state.activeCarwashSubTab = activeCarwashSubTab;
    },
    setPayStatus(state, payStatus) {
      state.payStatus = payStatus;
    },
    setTicketGroup(state, ticketGroup) {
      state.ticketGroup = ticketGroup;
    },
    setGift(state, gift) {
      state.gift = gift;
    },
    setActiveNoticeTab(state, activeNoticeTab) {
      state.activeNoticeTab = activeNoticeTab;
    },
    setActiveRecordTab(state, activeRecordTab) {
      state.activeRecordTab = activeRecordTab;
    },
    setErrMsg(state, errMsg) {
      state.errMsg = errMsg;
    }
  },
  actions: {
    updateMy(context, status) {
      context.commit("setMy", status);
    },
  },
  modules: {
  }
})
