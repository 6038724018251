<template>
  <v-app>
    <!-- <update v-if="isShow" /> -->
    <router-view />
  </v-app>
</template>

<script>
import moment from "moment-timezone";
import update from "@/views/update.vue";
import { FCMToken, getNotificationToken, showToolTab } from '@/apis/appBridge';

export default {
  name: 'App',
  components: { update },
  data: () => ({
    appToken: '',
    noticeToken: '',
    isShow: false,
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    // const token = getNotificationToken(); // 只有 android 會直接回 token
    // console.log('app init get FCM token:', token);
    const vm = this;
    if (!FCMToken) {
      vm.getDeviceToken(); //取得device token
    }
    // vm.setExpired();
    window.winClose = vm.winClose;
  },
  methods: {
    //取得device token
    getDeviceToken(retry = 0) {
      const vm = this;
      getNotificationToken();
      const r = retry + 1
      if (r < 3) {
        setTimeout(() => {
          //console.log('getDeviceToken,', FCMToken);
          vm.getDeviceToken(r)
        }, 1000)
      }
    },
    // setNotificationToken(token) {
    //   this.token = token
    //   return 'set notification token ='+token;
    // },
    setExpired() {
      const vm = this;
      const tmpStartDate = moment("2023-12-27 00:00:00").format("x");
      const tmpExpireDate = moment("2023-12-27 07:00:00").format("x");
      let tmpNowDate = moment().format("x");
      if (
        parseInt(tmpExpireDate) > parseInt(tmpNowDate) &&
        parseInt(tmpNowDate) > parseInt(tmpStartDate)
      ) {
        vm.isShow = true;
      }
    },
    winClose() {
      if (currentRouteName == "index" || currentRouteName == "notice" || currentRouteName == "accountMain") {
        showToolTab();
      }
    },
  }
};
</script>
